.component {
  position: relative;
  z-index: 0;

  & > .cameraButton {
    width: var(--size-40);
    height: var(--size-40);
  }

  & > .overlayLayout {
    z-index: 1;
  }
}

.cameraButton {
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  border-radius: 50%;
  backdrop-filter: blur(var(--blur-26));
  border: 1px solid var(--color-white--20);
  background-color: var(--color-black--10);
  touch-action: auto;
  pointer-events: auto;

  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);
  }
}

.iconLayout > * {
  transform: translateY(1px);
}

.description {
  padding-top: var(--size-8);
}

.componentOverlayImpl {
  display: flex;
  justify-content: flex-end;
  font-size: var(--font-size-12);
  color: var(--color-black);
  line-height: var(--line-height-text);

  & > * {
    width: max-content;
    max-width: 75vw;

    @media (--viewport-md) {
      max-width: 400px;
    }
  }
}
