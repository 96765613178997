.componentBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > .inputTextAreaLayout {
    width: 100%;
    height: 100%;
  }
}

.componentInputText,
.componentInputNumber {
  border: 1px solid;

  &:focus {
    border-color: hotpink;
  }
}

.componentInputTextarea {
  display: grid;
  transition: var(--duration-600) ease;
  transition-property: box-shadow transform;
  background-color: var(--color-white);
  border-radius: var(--radius-8);
  overflow: hidden;

  &.hasFocus {
    transform: translateY(calc(-1 * var(--size-2)));
    box-shadow: 0 6px 5px -3px var(--color-black--10);
  }

  & > * {
    grid-area: 1 / -1;
  }

  &.hasMaxLength {
    & > .inputTextarea {
      margin-bottom: var(--size-32);
    }
  }

  & > .valueLengthCounter {
    place-self: end;
    width: 100%;
  }
}

.inputTextarea {
  resize: none;
  padding: var(--size-16);
  transition: box-shadow var(--duration-600) ease;
  font-size: var(--font-size-14);
  line-height: var(--line-height-subheading);
  outline: none;
}

.valueLengthCounter {
  padding: var(--size-8) var(--size-16);
  font-size: var(--size-12);
  text-align: right;
  color: var(--color-gray-500);
  cursor: text;
}

.componentInputSelect {
  position: relative;
  border: 1px solid;
  display: flex;
  align-items: center;

  &:focus-within {
    border-color: hotpink;
  }

  &::after {
    content: '▼';
    flex-shrink: 0;
    position: absolute;
    right: 0;
    pointer-events: none;
  }
}

.select {
  cursor: pointer;
}

.componentDescription {
  font-style: italic;
}

.componentSelect {
  display: flex;
}

.componentCheckbox {
  display: flex;
  justify-content: flex-start;
}

.componentInputCheckbox {
  position: relative;
  display: flex;
  align-items: center;

  & > .checkboxLabel {
    position: static;
  }

  & > .inputCheckbox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & > .checkbox {
    order: -1;
  }
}

.inputCheckbox {
  opacity: 0;
}

.checkbox {
  border: 1px solid black;
  cursor: pointer;
  color: transparent;

  &.isChecked {
    color: inherit;
  }

  &.hasFocus {
    border-color: hotpink;
  }
}

.componentTextarea {
  display: flex;
}

.componentLabel {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.componentError {
  color: red;
}
