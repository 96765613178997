.componentBase,
.componentLinkBase {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-8);
  padding: var(--size-12) var(--size-24);
  border-radius: var(--size-20);
  box-shadow: var(--shadow-card);
  color: var(--color-gray-900);
  font-size: var(--font-size-14-16);
  line-height: 1.1;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  & > .iconLayout {
    max-width: var(--size-20);
  }

  & > .label {
    width: 100%;
  }
}

.componentIcon {
  padding: 0;
  display: grid;
  place-items: center;
}

.componentIcon,
.componentIconWithLabel,
.componentIconWithLabelReverse {
  line-height: 1;
}

.componentBase {
  background-color: var(--color-white);

  &:disabled {
    opacity: 0.5;
  }
}

.componentLinkPrimary {
  background-color: var(--color-white);
}

.componentLinkSecondary,
.componentSecondary {
  background-color: var(--color-gray-100);
}

.componentTertiary {
  color: var(--color-white);
  background-color: var(--color-black);
}

.label {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
