
.labelGroup {
  opacity: 0;
  transform: translateY(5px);
  transition:
    opacity 250ms ease,
    transform 300ms ease;

  &[data-is-hovered='true'] {
    transform: translateY(0);
    opacity: 1;
  }

  &[data-is-hovered='false'] {
    transform: translateY(5px);
    opacity: 0;
  }
}

.xAxisLine {
  stroke: var(--color-cream-400);
}

.isZero {
  stroke: var(--color-cream-500);
  stroke-width: 2;
}

.scaleX {
  transform: translate(-16px, 40px) rotate(-0.25turn);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-600);
  font-size: 16px;

  @media (--viewport-md) {
    transform: translate(0, 10px);
    font-size: 11px;
  }
}

.scaleY {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-600);
  font-size: 16px;

  @media (--viewport-md) {
    font-size: 11px;
  }
}

.svgScaleStroke {
  stroke: var(--color-cream-500);
  stroke-width: 2;
}

.svgScaleTick {
  transform: translate(0, 8px);
  stroke: var(--color-cream-500);
  stroke-width: 2;
}

.hoverTitle {
  text-anchor: middle;
  font-size: 11px;
}

.hoverDotGraphic,
.hoverLineGraphic {
  fill: var(--color-black);
}

.hoverLineGraphic {
  stroke: var(--color-black);
  stroke-width: 2;
}

.lineGraphLine {
  stroke-width: 2;
  fill: none;
}

.lineA {
  --color: var(--color-graph-orange);
  --outline: transparent;

  stroke: var(--color-graph-orange)
}
