.componentBase {
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;

  & > .playButtonLayout {
    z-index: 1;
    width: var(--size-64);
    left: 0;
    right: 0;
  }
}

.componentDeckFeed {
  flex-direction: column;
  gap: var(--size-8);
  color: var(--color-white);
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-600);
  font-style: italic;
}

.componentArticlePage {
  flex-direction: row;
  justify-content: center;
  gap: var(--size-12);
  color: var(--color-gray-400);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);

  & > .playButtonLayout {
    width: var(--size-40);
  }
}

.componentPlayButton {
  position: relative;
  background-color: var(--color-black--10);
  backdrop-filter: blur(var(--blur-26));
  border: 1px solid var(--color-white--20);
  border-radius: 100%;

  & > .ringLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.componentTextButton {
  display: grid;
  grid-template-areas: "content";

  & > * {
    grid-area: content;
    margin: 0 auto;
  }
}

.componentFadingLabel {
  color: inherit;
  transition: opacity 0.1s ease;
}

.componentPulsatingRings {
  position: relative;

  & > .ringLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.componentRing {
  display: block;
  border-radius: 50%;
  border: 1px solid var(--color-white);
  transform-origin: center;
  opacity: 0;
  animation: ringAnimation 2000ms ease-in infinite;

  &:nth-of-type(2) {
    animation-delay: 1000ms;
  }
}

@keyframes ringAnimation {
  0% {
    opacity: 0;
    scale: 1;
  }

  10% {
    opacity: 0.9;
    scale: 1;
  }

  100% {
    opacity: 0;
    scale: 1.4;
  }
}
