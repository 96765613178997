.componentBase {
  display: grid;
  place-items: center;
  grid-template-columns: minmax(0, 1fr);
  border-radius: var(--radius-20);
  box-shadow: var(--shadow-md);
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
  z-index: 0;

  & > * {
    grid-area: 1 / 1;
  }

  & > .buttonLayout {
    z-index: 1;
    position: static;
    place-self: start;
    align-self: end;
    margin: var(--size-16);
    max-width: calc(100% - (2 * var(--size-16)));
    min-width: 0;
  }
}

.imageAndLogo {
  position: relative;

  & > .logo {
    position: absolute;
    top: var(--size-16);
    left: var(--size-16);
  }
}
