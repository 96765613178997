.component {
  height: 100% !important;
  width: 100% !important;
  display: grid;

  & > .readMoreLayout {
    align-self: flex-end;
    margin-bottom: var(--size-80);

    @media (--viewport-md) {
      margin-bottom: var(--size-24);
    }
  }

  & > .regionAndIssueLayout {
    place-self: flex-end center;
    max-width: 80%;
    margin-top: 25%;
  }
}

.componentReadMore {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--size-12);
  color: var(--color-white);
  font-family: var(--font-family-base);
  font-size: var(--font-size-24-28);
  font-weight: var(--font-weight-400);
  font-style: italic;

  @media (--viewport-md) {
    gap: unset;
  }
}

.componentRegionAndIssue {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  color: var(--color-white);
  line-height: var(--line-height-heading);

  & > .issue {
    margin-top: var(--size-16);
  }
}

.region {
  font-size: var(--font-size-36-57);
}

.issue {
  font-size: var(--font-size-25-36);
}
