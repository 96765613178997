.componentBase {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  & > .logo {
    width: var(--size-100);
  }

  & > .text {
    width: 100%;
  }

  & > .backgroundGradient {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 120px;
    top: 0;
    margin: 0 auto;
  }
}

.text {
  color: var(--color-white);
  font-family: var(--font-family-base);
  font-size: var(--font-size-28-32);
  font-style: italic;
  text-align: center;
}

.backgroundGradient {
  background: var(--gradient-black-overlay-logo);
}
