.componentContent {
  background-color: var(--color-black);
  position: relative;
  overflow: hidden;

  & > .closeButtonLayout {
    position: absolute;
    width: fit-content;
    height: var(--size-40);
    bottom: var(--size-24);
    left: var(--size-24);

    @media (--viewport-lg) {
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  & > .controlsLayout {
    position: absolute;
    bottom: var(--size-24);
    right: var(--size-24);
  }

  & > .draggableZoomableImageLayout {
    width: 100%;
    height: 100%;
  }
}

.componentZoomControls {
  display: flex;
  gap: var(--size-8);

  & > .zoomButtonLayout {
    width: var(--size-32);
    height: var(--size-32);
  }
}

/* The parent of this component determines the size */
.componentDraggableZoomableImage {
  position: relative;
  touch-action: none;

  /* We position this component at the center, 50% top and left mean 50% of DraggableZoomableImage */
  & > .animatableImageLayout {
    position: relative;
    width: max-content;
    top: 50%;
    left: 50%;
  }
}

/* This component can now be translated to -50 + XXpx, the percentage means percentage of component itself */
.componentAnimatableImage {
  & > .imageLayout {
    max-width: 200vw; /* Make sure a large enough image is loaded, this can not be relative to the used width because that would take us into a chicken/egg problem with the parent div */
  }
}
