.componentBase {
  padding: var(--size-24);
  background-color: var(--color-white--20);
  backdrop-filter: var(--blur-26);
  border-radius: var(--radius-25);
  outline: 1px solid var(--color-white--20);
  text-align: center;
  -moz-appearance: textfield;
  transition: var(--duration-300) var(--ease);
  transition-property: outline-color, background-color;

  &::placeholder {
    color: var(--color-white--50);
  }

  &:hover {
    outline: 1px solid var(--color-white--10);
    background-color: var(--color-black--10);
  }

  &:focus,
  &.hasValue {
    text-align: left;
    outline: 1px solid var(--color-white--10);
    background-color: var(--color-black--10);

    &.hasIcon {
      padding-left: var(--size-52);
    }

    &::placeholder {
      color: transparent;
    }
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
