.componentBase {
  --distance: 0;

  animation: chevronAnimation var(--duration-2000) linear infinite;

  &.paused {
    animation: unset;
  }

  & > .iconLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}

@keyframes chevronAnimation {
  0% {
    opacity: 0;
    transform: translateY(calc(-100% * var(--distance)));
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(calc(50% * var(--distance)));
    scale: 0.8;
  }
}
