.component {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr var(--size-20);
  align-items: stretch;
  color: var(--color-gray-900);
  width: 100%;
  height: 100%;

  @media (--viewport-md) {
    padding: 0 var(--size-100);
  }

  &.noActiveProvince {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @media (--viewport-md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  & > .postcodeSelectorLayout {
    order: 1;
    place-self: center;
    width: 100%;

    @media (--viewport-sm) {
      width: unset;
    }

    @media (--viewport-md) {
      order: 0;
      margin-top: var(--size-64);
    }
  }

  & > .nudgeContainer {
    order: 2;
    width: max-content;
    height: max-content;
    margin: 0 auto var(--size-80);

    @media (--viewport-md) {
      grid-column: 1 / 3;
      grid-row: 2;
      margin-bottom: var(--size-64);
    }
  }
}

.nudgeContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--size-12);
  color: var(--color-white);

  @media (--viewport-md) {
    gap: var(--size-24);
  }

  & > .scrollNudgeLink {
    position: static;
  }
}

.scrollNudgeLink {
  position: relative;
  font-family: var(--font-family-base);
  font-size: var(--font-size-16-18);
  font-weight: var(--font-weight-400);
  font-style: italic;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.selectorContent {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    'active-province'
    'map'
    'issues'
    'actions';
  gap: var(--size-24);
  flex-direction: column;

  @media (--viewport-md) {
    padding-bottom: var(--size-24);
  }

  @media screen and (max-height: 640px) {
    gap: var(--size-8);
  }

  & > * {
    min-width: 0;
  }

  & > .mapLayout {
    z-index: 0;
    grid-area: map;
    align-self: center;
    height: 100%;
    min-height: 200px;
    max-height: 950px;
    top: var(--size-64);

    @media (--viewport-md) {
      height: 75svh;
    }

    &.activeProvinceLayout {
      height: 90%;
    }
  }

  & > .issuesLayout {
    z-index: 1;
    grid-area: issues;
    justify-self: center;
    width: 312px;
    max-width: calc(100vw - 2 * var(--size-20));
    margin-top: var(--size-40);

    @media screen and (max-height: 640px) {
      margin-top: var(--size-8);
    }
  }

  & > .activeProvince {
    z-index: 1;
    grid-area: active-province;
    align-self: center;
    justify-self: center;
    margin-top: 125px;
  }

  & > .hoveredProvince {
    z-index: 1;
    grid-area: 1 / 1 / -1 / -1;
    align-self: center;
    justify-self: center;
  }

  & > .actions {
    z-index: 1;
    grid-area: actions;
    justify-self: center;
  }
}

.backgroundOverlay {
  opacity: 0.5;
  backdrop-filter: blur(50px);
}

.mapContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    width: 100%;
    height: 100%;
  }
}

.activeProvince {
  color: var(--color-white);
  font-style: italic;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-36);
  font-size: var(--font-size-36-57);
  line-height: var(--line-height-heading);
  text-align: center;
  pointer-events: none;
  user-select: none;
}

.hoveredProvince {
  color: var(--color-white);
  font-style: italic;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-55);
  font-size: var(--font-size-55-115);
  line-height: var(--line-height-heading);
  text-align: center;
  pointer-events: none;
  user-select: none;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-32);
  padding-bottom: var(--size-24);
  color: var(--color-white);

  & > .nextBank,
  & > .previousBank {
    width: var(--size-24);
    margin-top: 3px;
  }

  & > .nextBank {
    order: 1;
  }

  & > .previousBank {
    order: -1;
  }
}

.previousBank,
.nextBank {
  transition: opacity var(--duration-300);

  &[disabled] {
    opacity: 0.3;
  }

  & > .iconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.previousBank {
  transform: scale(-1);
}

.slider {
  overflow: visible !important;
  align-items: stretch;

  & > .slide {
    width: 100%;
  }
}

.slide {
  display: flex;
  align-items: stretch;
  overflow: visible !important;

  & > .issueLayout {
    width: 100%;
    height: 100%;
  }
}

.backButton {
  color: var(--color-white);
  display: flex;
  align-items: center;
  gap: var(--size-8);
  font-weight: var(--font-weight-300);

  & > .iconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.componentIssues {
  pointer-events: none;

  &.hasContent {
    pointer-events: auto;
  }
}

.componentIssue {
  display: flex;
  gap: var(--size-12);
  background-color: var(--color-white);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-600);
  line-height: 1;
  border-radius: var(--radius-12);
  box-shadow: var(--shadow-card);
  transition: transform var(--duration-300), background-color var(--duration-300);
  transform-origin: bottom;
  cursor: grab;
  padding-right: var(--size-16);
  overflow: hidden;
  position: relative;
  aspect-ratio: 8 / 3;

  &.selected {
    cursor: pointer;
    transform: translateY(calc(-1 * var(--size-16))) scale(1.05);
  }

  &:active {
    cursor: grabbing;
  }

  & > .issueTitle {
    align-self: center;
  }

  & > .issueIcon {
    position: absolute;
    right: var(--size-16);
    bottom: var(--size-16);
  }

  & > .issueImageLayout {
    max-width: 40%;
    height: 100%;
  }

  &:hover,
  &:focus {
    & > .issueIcon {
      transform: translateX(50%);
    }
  }
}

.issueIcon {
  display: block;
  transition: transform var(--duration-300), opacity var(--duration-300);
  opacity: 0.5;
  max-width: var(--size-16) !important;
  max-height: var(--size-16) !important;

  &.selected {
    opacity: 1;
  }

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

.issueTitle {
  display: block;
  padding: var(--size-16);
}

.componentMap {
  z-index: 0;
  position: relative;
  contain: layout;
  text-align: center;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
  }

  & > .headingContainer {
    width: 100%;
    margin-top: var(--size-48);
  }

  & > .activeMarkerLayout {
    z-index: 1;
  }
}

.headingContainer {
  color: var(--color-white);
  pointer-events: none;
}

.componentProvinces {
  transform-origin: top left;
  overflow: visible;
}

.componentProvince {
  fill: var(--color-white);
  opacity: 0.2;
  transition: opacity var(--duration-300);
  cursor: pointer;

  &.noProvincesActive {
    @media not (--viewport-md) {
      opacity: 0.4;
    }
  }

  &.active,
  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.4;
    transition-duration: 0;
  }
}

.componentMarker {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 1px !important;
  height: 1px !important;

  & > * {
    flex: 0 0 auto;
  }
}

.markerIcon {
  padding: var(--size-8);
  border-radius: var(--radius-max);
  border-bottom-left-radius: 0;
  background-color: var(--color-white);
  transform-origin: bottom left;
  transition: transform var(--duration-300), background-color var(--duration-300), color var(--duration-300);

  &:hover,
  &:focus {
    background-color: var(--color-gray-100);
  }

  &.active {
    background-color: var(--color-gray-900);
    color: var(--color-white);
    transform: scale(1.2);
  }

  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);
  }
}

.componentCluster {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1px !important;
  height: 1px !important;

  & > * {
    flex: 0 0 auto;
    width: var(--size-32);
    height: var(--size-32);
  }
}

.clusterIcon {
  border-radius: var(--radius-max);
  background-color: var(--color-white);
  transform-origin: center;
  font-weight: var(--font-weight-600);
  transition: background-color var(--duration-300), color var(--duration-300);
  line-height: var(--size-32);

  &:hover,
  &:focus {
    background-color: var(--color-gray-100);
  }

  &.active {
    background-color: var(--color-gray-900);
    color: var(--color-white);
  }
}

.componentPostcodeInput {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-white);

  & > .inputLayout {
    width: 100%;
    height: var(--size-48);
  }

  & > .inputButtonLayout {
    position: absolute;
    right: var(--size-16);
    height: var(--size-48);
  }

  & > .notificationLayout {
    position: absolute;
    top: 60px;
  }
}

.componentInputButton {
  color: var(--color-white--50);
  transition: color var(--duration-200) ease-in-out;

  &:hover {
    color: var(--color-white);
  }

  & > .iconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.notificationIconColor {
  color: var(--color-red-100);
}

.componentPostcodeSelector {
  padding: 0 var(--size-24) var(--size-24);
  color: var(--color-white);
  text-align: center;

  & > .chooseTitleLayout {
    margin-bottom: var(--size-16);
  }

  & > .helpText {
    margin-bottom: var(--size-8);
    width: 100%;
  }
}

.helpText {
  font-style: italic;
  font-size: var(--font-size-14-16);
  text-align: center;
}
