.componentBase {
  padding-bottom: var(--size-48);
  background-color: var(--color-white);

  @media (--viewport-md) {
    padding-bottom: var(--size-64);
  }
}

.componentCentered {
  align-items: center;
  text-align: center;
}
