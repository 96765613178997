.component {
  position: relative;

  & > .closeButtonLayout {
    position: absolute;
    left: 0;
    right: 0;
    bottom: var(--size-24);
    margin: auto;

    @media (--viewport-md) {
      top: var(--size-24);
      right: var(--size-24);
      left: auto;
      bottom: auto;
    }
  }

  & > .cardLayout {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}

.componentPopupContentCard {
  overflow-y: auto;

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &.green {
    background-color: var(--color-green-200);
  }

  &.white {
    background-color: var(--color-white);
  }
}

.componentPopupContentCardContentOnly {
  background-color: var(--color-white);
}

.componentCloseButton {
  display: grid;
  place-items: center center;
  height: var(--size-48) !important;
  width: var(--size-48) !important;
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-black);

  & > .closeIconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.componentContent {
  overflow-y: auto;
  padding: var(--size-24);
  padding-bottom: var(--size-80);

  @media (--viewport-md) {
    padding-top: var(--size-100);
    padding-left: var(--size-48);
    padding-right: var(--size-48);
  }

  & > .headingGroupLayout {
    margin-bottom: var(--size-12);

    @media (--viewport-md) {
      margin-bottom: var(--size-16);
    }
  }
}
