.componentSlider {
  --slides-per-view: 1.1;

  @media (--viewport-md) {
    --slides-per-view: 2.8;
  }

  display: grid;
  grid-auto-flow: column;
  overflow: visible !important;
}

._rootSlide {
  width: auto !important;
}

.slide {
  --container-width: calc(min(100vw, var(--container-max-width-lg)) - (2 * var(--container-padding-x)));
  --slide-width: calc(var(--container-width) / var(--slides-per-view));
  --spacing: var(--size-16);

  overflow: visible !important;

  & > .articleCardLayout {
    width: var(--slide-width);
  }

  &:not(:last-child) {
    & > .articleCardLayout {
      margin-right: var(--spacing);
    }
  }
}
