.component {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-4) var(--size-4);
  padding-bottom: var(--size-48);
  flex-wrap: wrap;
  padding-top: var(--size-40);

  &.isCentered {
    align-items: center;
  }
}

.componentTag {
  padding: var(--size-4) var(--size-16);
  background-color: var(--color-black--10);
  border-radius: var(--radius-50);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  color: var(--color-gray-900);
}
