.componentOverview {
  z-index: 0;
  position: relative;

  & > .menuLayout {
    z-index: 1;
    position: fixed;
    width: fit-content;
    right: 0;
    left: 0;
    bottom: var(--size-24);
    margin: 0 auto;
  }

  & > .searchHeroLayout {
    width: 100%;
  }

  & > .filterTagsLayout {
    width: 100%;
  }
}

.componentSearchHero {
  z-index: 0;
  position: relative;
  overflow: hidden;

  & > .heroImageContainer {
    z-index: -1;
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
  }
}

.heroContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-40);
  padding: var(--size-32) 0 var(--size-72);

  & > .logoLayout {
    width: var(--size-100);
  }

  & > .searchFormContainer {
    width: 100%;
  }
}

.searchFormContainer {
  display: flex;
  gap: var(--size-8);

  & > .searchLayout {
    flex-grow: 1;
  }

  & > .filterModalButtonLayout {
    width: var(--size-48);
  }
}

.componentFilterModalButton {
  z-index: 0;
  position: relative;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-white--20);
  backdrop-filter: var(--blur-26);
  transition: background-color var(--duration-300) var(--ease);

  &:hover {
    background-color: var(--color-black--10);
  }

  & > .iconLayout {
    width: var(--size-20);
    height: var(--size-20);
  }

  & > .activeFiltersCount {
    z-index: 1;
    position: absolute;
    width: var(--size-24);
    height: var(--size-24);
    top: calc(-1 * var(--size-8));
    right: calc(-1 * var(--size-8));
  }
}

.activeFiltersCount {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color-gray-900);
  background-color: var(--color-white);
  font-size: var(--font-size-16);
  line-height: 1;
}

.heroImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: blur(var(--blur-26));

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--color-black--20);
  }

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.componentTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-white);
}

.title {
  font-size: var(--font-size-38);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-heading-group-title);
}

.subtitle {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-subheading);
  text-align: center;
}

.componentFilterTags {
  display: flex;
  justify-content: center;
  overflow: visible;

  & > .filterTagsContainer {
    @media (--viewport-md) {
      max-width: var(--container-max-width-lg);
    }
  }
}

.filterTagsContainer {
  display: flex;
  gap: var(--size-8);
  overflow-y: scroll;
  padding: var(--size-24) var(--container-padding-x);

  @media (--viewport-md) {
    flex-wrap: wrap;
    padding: var(--size-40) var(--container-padding-x) var(--size-24);
    overflow: hidden;
  }

  & > * {
    min-width: max-content;
  }
}

.componentOverviewBody {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: var(--size-12) 0 var(--size-80);

  & > .paginationLayout {
    margin-top: var(--size-24);
  }
}

.overviewBodyTextContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: var(--size-8);
  padding-top: var(--size-52);
  color: var(--color-gray-900);
}

.componentArticlesFoundNumber {
  font-size: var(--font-size-14-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-subheading);
  font-style: italic;
}

.componentPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-8);

  @media (--viewport-md) {
    gap: var(--size-12);
  }

  & > .paginationButtonLayout {
    width: var(--size-40);
    height: var(--size-40);

    @media (--viewport-md) {
      width: var(--size-48);
      height: var(--size-48);
    }
  }
}

.componentPaginationButton,
.componentPaginationButtonChevron {
  --scale-background: 0;

  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    --scale-background: 1;
  }

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--color-gray-100);
    transform: scale(var(--scale-background));
    transition: transform var(--duration-200) var(--ease);
  }

  &.isCurrent {
    &::after {
      display: none;
    }
  }

  &.isCurrent {
    color: var(--color-white);
    background-color: var(--color-gray-900)
  }
}

.componentPaginationButtonChevron {
  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);
  }
}

.componentArticlesGrid {
  display: flex;
  flex-direction: column;
  gap: var(--size-48) var(--size-16);

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.componentFilterModalContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: var(--size-40) var(--size-16) var(--size-100);
  background-color: var(--color-white);

  @media (--viewport-md) {
    gap: var(--size-48);
    padding: var(--size-40) var(--size-48) var(--size-64);
  }
}

.componentFilterItems {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  & > .expandButtonLayout {
    align-self: center;
    width: max-content;
    margin-top: var(--size-12);
  }
}

.filterItemsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);
}
