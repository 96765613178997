.component {
  z-index: 0;
  position: relative;

  & > .menuLayout {
    z-index: 2;
    position: fixed;
    width: fit-content;
    right: 0;
    left: 0;
    bottom: var(--size-24);
    margin: 0 auto;
  }

  & > .splashLayout {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    height: 100svh;
  }

  & > .issuesSlidersContainer,
  & > .specialsSlidersContainer {
    width: 100%;
    overflow: hidden;
  }

  & > .footerLayout {
    margin-top: var(--size-80);
  }

  & > .searchLayout {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    top: 0;
  }
}

.container {
  & > .selectorContainer {
    width: 100%;
    height: 100svh;
    overflow: hidden;
  }
}

.specialsSlidersContainer {
  position: relative;
  padding-top: var(--size-80);
  color: var(--color-white);
}

.issuesSlidersContainer {
  position: relative;
  padding-top: var(--size-80);
  color: var(--color-white);
}

.componentBottomGradient {
  pointer-events: none;
  position: relative;

  & > .gradient {
    position: fixed;
    width: 100%;
    height: 100vh;
    bottom: 0;
  }
}

.gradient {
  background: linear-gradient(180deg, transparent 0%, var(--color-green-300) 45%);
  transform-origin: bottom;
}
