.componentBase {
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--size-4);
  padding-right: var(--size-16);
  border-radius: var(--radius-32);
  color: var(--color-gray-900);
  transition: background-color var(--duration-200) ease;
  overflow: hidden;

  &.isActive {
    color: var(--color-white);
    background-color: var(--color-black);

    &:hover {
      background-color: var(--color-gray-900);
    }
  }

  & > .iconLayout {
    width: var(--size-8);
    height: var(--size-8);
  }
}

.label {
  padding: var(--size-12) var(--size-24);
  padding-right: var(--size-4);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600);
}

.componentGreen {
  background-color: var(--color-green-100--50);

  &:hover {
    background-color: var(--color-green-100);
  }
}

.componentCream {
  background-color: var(--color-cream-100--50);

  &:hover {
    background-color: var(--color-cream-100);
  }
}

.componentCyan {
  background-color: var(--color-cyan-100--50);

  &:hover {
    background-color: var(--color-cyan-100);
  }
}

.componentWhite {
  background-color: var(--color-white);

  &:hover {
    background-color: var(--color-white);
  }
}
