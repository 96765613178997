.componentBase {
  pointer-events: none;
  position: relative;
  object-fit: cover;

  & > .video {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  & > .captionContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: var(--size-24);

    @media (--viewport-md) {
      padding: var(--size-40);
    }
  }
}

.componentPortrait {
  --padding-top: 120%;

  aspect-ratio: 5 / 7;
}

.componentSquare {
  --padding-top: 100%;

  aspect-ratio: 1;
}

.componentLandscape {
  --padding-top: 56.25%;

  aspect-ratio: 16 / 9;
}

.video {
  object-fit: cover;
}
