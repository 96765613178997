.componentImageWithButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-32);
  padding: var(--size-40) 0;
  background-color: var(--color-cyan-300--50);

  & > .imageLayout {
    max-width: 75%;
    max-height: 100%;
  }

  & > .buttonLayout {
    width: fit-content;
    height: var(--size-40);
  }
}
