.page {
  display: grid;
  grid-template-areas: 'content';
  position: relative;
  z-index: 0;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;

  & > .contentLayout {
    width: 100%;
    height: 100%;
    grid-area: content;
    z-index: 1;
  }
}
