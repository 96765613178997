
.component {
  display: flex;
  gap: var(--size-12);
  position: relative;
  z-index: 0;

  & > .creditsButtonLayout {
    width: var(--size-40);
    height: var(--size-40);
    z-index: 1;
  }

  & > .shareButtonLayout {
    width: var(--size-40);
    height: var(--size-40);
    z-index: 1;
  }
}

.captionText {
  padding: var(--size-8);
  border-radius: var(--radius-15);
  background-color: var(--color-white);
  line-height: var(--line-height-text);
  text-align: right;
}
