.componentBase {
  position: relative;
  aspect-ratio: var(--aspect-ratio);
  contain: style layout paint;
  overflow: hidden;
}

.imageContainer {
  & > .imageCoverLayout {
    width: 100%;
  }
}
