.component {
  overflow: hidden;

  & > * {
    margin: 0 auto;
  }

  & > .carousel {
    width: 100vw;
    max-width: 100%;

    @media (--viewport-md) {
      max-width: var(--container-max-width-lg);
    }
  }
}

.captionContainer {
  padding: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-40);
  }
}

.carousel {
  position: relative;

  & > .slideBarsContainer {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  & > .captionContainer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.slide {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.componentImageSlide {
  pointer-events: none;
  position: relative;

  &::after {
    pointer-events: none;
    position: absolute;
    content: '';
    width: 100%;
    height: var(--size-80);
    top: 0;
    left: 0;
    background: var(--gradient-black);
  }
}

.slideBarsContainer {
  display: flex;
  gap: var(--size-12);
  padding: 0 var(--size-16);

  & > .slideBarLayout {
    flex: 1;
  }
}

.componentSlideBar {
  padding: var(--size-16) 0;

  & > .progressBar {
    width: 100%;
    height: 2px;
  }
}

.progressBar {
  pointer-events: none;
  position: relative;
  background-color: var(--color-white--50);
  overflow: hidden;

  & > .progressFill {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.progressFill {
  background-color: var(--color-white);
  transform: scaleX(1);
  transform-origin: left;
}
