@kaliber-scoped static-content;

article {
  & > h3,
  & > h2 {
    margin-top: var(--size-24);
    margin-bottom: 0;
  }

  & > p {
    margin-bottom: var(--size-12);
  }
}

h2 {
  font-size: var(--font-size-24-28);
  font-weight: bold;
}

h3 {
  font-size: var(--font-size-18-22);
  font-weight: bold;
}

p {
  font-size: var(--font-size-16-18);
}

a {
  text-decoration: underline;
}
