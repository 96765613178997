:root {
  --gradient-cream: linear-gradient(180deg, var(--color-cream-100) 0%, var(--color-cream-200) 100%);

  --gradient-green: linear-gradient(180deg, var(--color-green-100) 0%, var(--color-green-200) 100%);

  --gradient-cyan: linear-gradient(180deg, var(--color-cyan-100) 0%, var(--color-cyan-300) 100%);
  --gradient-cyan-reverse: linear-gradient(0deg, var(--color-cyan-100) 0%, var(--color-cyan-300) 100%);

  --gradient-black: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, var(--color-black--40) 100%);
  --gradient-black-overlay: linear-gradient(20deg, var(--color-black--60) 0%, rgba(0, 0, 0, 0) 65%);
  --gradient-black-overlay-logo: linear-gradient(180deg, var(--color-black--20) 0%, rgba(0, 0, 0, 0) 65%);
}
