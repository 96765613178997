.componentBase {
  pointer-events: none;
  overflow: hidden;
  box-shadow: 0 35px 17px -16px rgba(48, 25, 4, 0.11);
  border-radius: inherit;

  &.componentWithoutShadow {
    box-shadow: none;
  }

  & > .imageAnimation {
    width: 100%;
    height: 100%;
  }
}

.imageAnimation {
  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}
