.component {
  z-index: 0;
  position: relative;
  pointer-events: none;

  & > .overlay {
    z-index: -1;
  }
}

.container {
  pointer-events: none;
  display: grid;
  grid-template-columns: repeat(2, auto);
  user-select: none;

  & > .buttonLayout {
    height: max-content;
  }
}

.overlay {
  opacity: 0;
  pointer-events: none;
  background: var(--color-black--60);
  transition: opacity var(--duration-600) ease;

  &.isOpen {
    opacity: 1;
    pointer-events: auto;
  }
}

.componentButton {
  pointer-events: auto;
  display: grid;
  align-items: center;
  padding: var(--size-8);
  border-radius: var(--radius-8) var(--radius-8) 0 0;
  transform: rotate(-90deg) translateY(-100%);
  transform-origin: top right;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
  color: var(--color-white);
  background-color: var(--color-gray-500);
  transition: background-color var(--duration-200) ease;

  &:hover {
    background-color: var(--color-gray-600);
  }

  & > * {
    grid-area: 1 / -1;
  }

  & > .drawerButtonLabel {
    width: 100%;
  }

  & > .drawerButtonIconContainer {
    width: var(--size-8);
    height: var(--size-8);
    justify-self: end;
  }
}

.drawerButtonLabel {
  padding-inline: var(--size-8);
  transition: transform var(--duration-400) ease;
  text-align: center;

  &.isOpen {
    transform: translateX(calc(-1 * var(--size-8)));
  }
}

.drawerButtonIconContainer {
  opacity: 0;
  transition: opacity var(--duration-400) ease;

  &.isOpen {
    opacity: 1;
  }

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

.componentContent {
  pointer-events: none;
  display: grid;
  grid-template-columns: 0fr;
  transition: var(--duration-600) ease;
  transition-property: grid-template-columns, padding;
  background-color: var(--color-cyan-300);
  border-bottom-left-radius: var(--radius-8);
  overflow: hidden;

  &.isOpen {
    pointer-events: auto;
    grid-template-columns: 1fr;
  }

  & > * {
    min-width: 0;
  }
}

.contentContainer {
  & > .contentInnerContainer {
    width: 325px;

    @media (--viewport-md) {
      width: 350px;
    }
  }
}

.radioItem {
  display: flex;
  padding: var(--size-4);
  border-radius: var(--radius-4);

  &:focus-within {
    outline-offset: var(--size-2);
    outline: var(--size-2) solid var(--color-white--50);
  }

  & > .inputEmojiRadio {
    height: 0;
    width: 0;
  }
}

.componentFormContent {
  padding: var(--size-24);
}

.componentFormContent,
.componentSentContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.componentSentContent {
  & > .heartImage {
    align-self: center;
    width: 200px;
    margin-block: var(--size-32) var(--size-64);
  }
}

.noLineBreak {
  white-space: nowrap;
}

.text {
  color: var(--color-gray-400);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-text);
}

.componentForm {
  display: flex;
  flex-direction: column;
}

.conditionalContainer {
  opacity: 0;
  pointer-events: none;
  display: grid;
  grid-template-rows: 0fr;
  transition: var(--duration-400) ease;
  transition-property: grid-template-rows opacity padding;
  overflow: hidden;

  &.hasValue {
    opacity: 1;
    pointer-events: auto;
    grid-template-rows: 1fr;
    padding-block: var(--size-12);
  }

  & > * {
    min-height: 0;
  }
}

.conditionalFields {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  padding-top: var(--size-12);

  & > .textareaLayout {
    width: 100%;
    height: 150px;
  }
}

.componentEmojiRadioGroup {
  display: flex;
  gap: var(--size-8);
  justify-content: space-around;

  @media (--viewport-md) {
    gap: var(--size-16);
  }
}

.inputEmojiRadio {
  opacity: 0;
}

.radioLabel {
  --emoji-scale: 0.75;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-8);
  color: var(--color-black);
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
  white-space: nowrap;

  &::after {
    content: '';
    width: var(--size-24);
    height: var(--size-2);
    background-color: var(--color-gray-500);
    transform: scaleX(0);
    transition: transform var(--duration-200) ease;
  }

  &:active {
    --emoji-scale: 0.6;
  }

  &.checked {
    --emoji-scale: 1;

    &:active {
      --emoji-scale: 0.95;
    }

    &::after {
      transform: scaleX(1);
    }
  }
}

.emoji {
  transform: scale(var(--emoji-scale));
  font-size: var(--font-size-42);
  transform-origin: bottom center;
  transition: transform var(--duration-200) ease;
}

.componentButtonBase {
  --icon-rotation: 0deg;

  display: grid;
  align-items: center;
  padding: var(--size-12) var(--size-24);
  border-radius: var(--radius-25);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  color: var(--color-gray-400);
  background-color: var(--color-white);
  transition: var(--duration-300) ease;
  transition-property: box-shadow transform;

  &:hover {
    transform: translateY(calc(-1 * var(--size-2)));
    box-shadow: 0 6px 5px -3px var(--color-black--10);
  }

  & > * {
    grid-area: 1 / -1;
  }

  & > .buttonLabel {
    width: 100%;
  }

  & > .buttonIconContainer {
    justify-self: end;
    width: var(--size-16);
    height: var(--size-16);
  }

  &[type=button] {
    --icon-rotation: 180deg;

    & > .buttonIconContainer {
      justify-self: start;
    }
  }
}

.buttonLabel {
  padding-inline: var(--size-32);
}

.buttonIconContainer {
  transform: rotate(var(--icon-rotation));

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
