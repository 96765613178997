.component {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-24) 0;

  &:has(+ *) {
    border-bottom: 1px solid var(--color-black);
  }

  & > .deckItemContentLayout {
    flex: 2;
    margin: auto 5%;
  }

  & > .deckItemImageLayout {
    flex: 1;
  }
}

.componentContent {
  position: relative;
  display: flex;
  flex-direction: column;

  & > .deckItemContentAnchor {
    position: static;
  }
}

.rubricText {
  font-family: var(--font-family-base);
  font-size: var(----font-size-14);
  font-weight: var(----font-weight-400);
  color: var(--color-black);
}

.deckItemContentAnchor {
  position: relative;
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-600);
  line-height: 3rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.componentImage {
  aspect-ratio: 1;
  overflow: hidden;

  & > .deckItemImageLayout {
    width: 100%;
    height: 100%;
  }
}
