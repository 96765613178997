.component {
  z-index: 0;
  position: relative;
  background-color: var(--color-white);

  & > .menuLayout {
    z-index: 4;
    position: fixed;
    width: fit-content;
    right: 0;
    left: 0;
    bottom: var(--size-24);
    margin: 0 auto;
  }

  &.withBarHidden {
    & > .previousLayout {
      --offset: 10px;
      --height: calc(var(--size-32));
    }
  }

  & > .previousLayout {
    --height: calc(var(--size-32) - (2 * var(--size-4)));

    position: fixed;
    left: 50%;
    height: var(--height);
    margin-top: var(--size-4);
    z-index: 2;
    top: 0;
  }

  & > .deckTocLayout,
  & > .searchLayout {
    z-index: 3;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
  }
}

.componentPreviousArticleButton {
  --opacity: 1;

  z-index: 0;
  position: relative;
  display: flex;
  gap: var(--size-12);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600);
  transform-origin: center center;
  transition:
    transform  var(--duration-400) ease-in-out,
    height  var(--duration-400) ease-in-out,
    opacity var(--duration-100) ease;
  color: var(--color-white);
  transform: translateX(-50%) translateY(var(--offset, 0));
  line-height: 1;
  padding: var(--size-4) var(--size-12);
  border-radius: var(--size-100);
  user-select: none;

  /* Using opacity here, because 'regular' opacity breaks blur in Chrome. */
  backdrop-filter: opacity(var(--opacity)) blur(5px);

  &:hover,
  &:focus {
    --opacity: 0.9;
  }

  &.needsBackground {
    &::before {
      z-index: -1;
      content: '';
      position: absolute;
      border-radius: var(--radius-20);
      background-color: var(--color-black--10);
      border: 1px solid var(--color-white--20);
      inset: 0;
    }
  }

  & > .iconLayout {
    width: var(--size-12);
    height: var(--size-12);
  }
}

.component,
.componentWithoutContext {
  z-index: 0;
  position: relative;
  background-color: var(--color-white);

  & > .menuLayout {
    z-index: 4;
    position: fixed;
    width: fit-content;
    right: 0;
    left: 0;
    bottom: var(--size-24);
    margin: 0 auto;
  }

  & > .readProgressionLayout {
    z-index: 1;
    position: fixed;
    width: 100vw;
    max-width: 100%;
    height: var(--size-32);
    top: 0;
    left: 0;
  }

  & > .previousLayout {
    z-index: 2;
  }

  & > .deckTocLayout,
  & > .searchLayout {
    z-index: 3;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
  }
}

.componentBodyBase {
  z-index: 0;
  position: relative;
  overflow: hidden;

  & > .previousLayout {
    z-index: -1;
    position: fixed;
    width: max-content;
    justify-self: center;
    top: var(--size-40);
    left: 50%;
  }

  & > .articleHeroContainerLayout {
    z-index: -3;
    position: fixed;
    width: 100%;
    height: 0;
    top: 0;
    overflow: visible;
  }

  & > .articleButtonsContainerLayout {
    position: fixed;
    z-index: -1;
    height: max-content;
    margin-right: 5vw;
    margin-top: calc(100svh - var(--size-100) - var(--size-40));
    right: 0;

    @media (--viewport-md) {
      margin-right: calc(5vw + var(--size-24));
    }
  }

  & > .articleIntroLayout {
    z-index: -2;
    width: 100%;
    height: 100vh;
    height: 100svh;
  }

  & > .cardBorderLayout {
    z-index: 1;
    height: var(--size-48);
    margin-top: calc(var(--size-48) * -1);
  }

  & > .readMoreLayout {
    padding: 0;
  }

  & > .scalingCardLayout {
    z-index: 1;
  }

  &.componentBodyWithNextArticle {
    & > .nextArticleLayout {
      z-index: -1;
      height: 100vh;
      height: calc(100lvh + var(--size-100));
    }
  }
}

.componentCardBorder {
  position: sticky;
  background-color: var(--color-white);
  border-top-left-radius: var(--radius-20);
  border-top-right-radius: var(--radius-20);
  transform-origin: bottom center;
  box-shadow: var(--shadow-sm);
}

.goBackContainer {
  padding-bottom: var(--size-100);
  background-color: var(--color-white);
}

.articleHeroContainer {
  & > * {
    width: 100%;
    height: 100vh;
    height: 100lvh;

    @media (--viewport-md) {
      min-height: 750px;
    }
  }
}

.componentSnackableArticle {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--size-24) var(--size-48);
  padding-bottom: var(--size-24);

  @media (--viewport-sm) {
    flex-direction: row;
    padding-bottom: var(--size-48);
  }

  & > * {
    flex: 1;
  }
}

.articleContentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  padding: var(--size-12) 0;
}

.contentTitleContainer {
  hyphens: auto;
}

.componentContentGedicht {
  padding-top: var(--size-64);

  & > .audioLayout {
    margin-bottom: var(--size-32);
  }

  & > .nameAndPositionLayout {
    margin-top: var(--size-32);
  }

  & > .kaderLayout {
    margin-top: var(--size-32);
  }
}

.nameAndPosition {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  & > .buttonLayout {
    width: fit-content;
    height: var(--size-40);
  }
}

.componentContent {
  & > .contentContainerLayout {
    padding: 0;
  }
}
