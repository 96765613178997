.component {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-8);

  & > .textContainer {
    position: static;
  }
}

.imageContainer {
  z-index: 0;
  position: relative;
  display: grid;
  border-radius: var(--radius-20);
  overflow: hidden;

  & > * {
    grid-area: 1 / -1;
  }

  & > .image {
    z-index: -1;
  }

  & > .metaTagsLayout {
    z-index: 1;
    width: 100%;
  }
}

.componentMetaTags {
  display: flex;
  justify-content: space-between;
  padding: var(--size-8);

  & > * {
    height: max-content;
  }
}

.componentMetaTag {
  padding: var(--size-8) var(--size-16);
  border-radius: var(--radius-50);
  color: var(--color-white);
  background-color: var(--color-black--20);
  backdrop-filter: blur(var(--blur-15));
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-4);

  & > .anchorLayout {
    position: static;
  }
}

.componentCardAnchor {
  --scale-icon: 0;

  position: relative;
  display: flex;
  gap: var(--size-8);

  &:hover {
    --scale-icon: 1;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  & > .titleLayout {
    flex: 1;
  }

  & > .iconContainer {
    width: var(--size-40);
    height: var(--size-40);
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-gray-900);
  transform: scale(var(--scale-icon));
  transition: transform var(--duration-200) var(--ease);

  & > .iconLayout {
    width: 25%;
  }
}

.componentCardTags {
  display: flex;
  gap: 0 var(--size-8);
  flex-wrap: wrap;
}

.componentCardTagBase {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);

  &::after {
    margin-left: var(--size-8);
  }
}

.componentCardTagRubric {
  color: var(--color-gray-900);

  &:not(:only-child) {
    &::after {
      content: '•';
    }
  }
}

.componentCardTag {
  color: var(--color-gray-500);

  &:not(:last-of-type) {
    &::after {
      content: '-';
    }
  }
}
