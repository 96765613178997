.gradientOverlayBlack {
  z-index: 0;
  position: relative;

  &::after {
    z-index: 0;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gradient-black-overlay);
    border-radius: inherit;
    pointer-events: none;
  }
}
