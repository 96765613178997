:root {
  --radius-4: 4px;
  --radius-8: 8px;
  --radius-12: 12px;
  --radius-15: 15px;
  --radius-20: 20px;
  --radius-25: 25px;
  --radius-32: 32px;
  --radius-50: 50px;
  --radius-max: 999px;
}
