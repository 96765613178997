.component,
.componentButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-8) var(--size-16);
  border-radius: var(--radius-50);
  filter: opacity(var(--opacity, 1));
  backdrop-filter: blur(5px);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: var(--line-height-heading);
}

.componentButton {
  z-index: 0;
  position: relative;
  overflow: hidden;
  pointer-events: auto;

  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    border-radius: var(--radius-20);
    background-color: var(--color-white--20);
    inset: 0;
  }

  & > .iconLayout {
    margin-right: var(--size-4);
    height: var(--size-10);
    width: var(--size-10);
  }
}

.componentWrapper {
  display: flex;
  flex-wrap: wrap;
  font-family: var(--font-family-base);
  gap: var(--size-4);
}
