.componentBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  &.isCentered {
    justify-content: center;
  }
}

.component {
  padding-top: var(--size-40);
}

.tagsTitle {
  font-family: var(--font-family-base);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);

  &.isCentered {
    justify-content: center;
  }
}
