.component {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-32);
  color: var(--color-white);
  background-color: #141240; /* custom color for this component only */
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  font-size: var(--font-size-16);
  line-height: var(--line-height-text);
}

.title {
  font-family: var(--font-family-base);
  line-height: var(--line-height-heading);
  font-size: var(--font-size-28);
  font-size: var(--font-size-28-32);
  font-style: italic;
  letter-spacing: -1px;
}

.componentActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-16);
  text-transform: uppercase;

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--size-24);
  }

  & > .button {
    width: 100%;

    @media (--viewport-md) {
      width: max-content;
    }
  }
}

.button {
  display: inline-block;
  padding: var(--size-12) var(--size-32);
  border-radius: var(--radius-4);
  font-weight: var(--font-weight-600);
  color: var(--color-white);
  background-color: transparent;
  border: 1px solid var(--color-white);
  text-align: center;
  text-transform: uppercase;
  line-height: var(--line-height-heading);

  &:not(:disabled) {
    &:hover {
      background-color: var(--color-white);
      color: #141240; /* custom color for this component only */
      border-color: var(--color-white);
    }

    &:focus {
      border-color: var(--color-white);
    }
  }
}
