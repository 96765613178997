.component {
  display: grid;
  row-gap: var(--size-12);
  grid-template-columns: [content] 1fr [arrow] auto;
  grid-template-rows: 1fr max-content;
  padding: calc(var(--size-32) + var(--size-16));
  color: var(--color-white);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    grid-template-rows: 1fr;
    grid-template-columns: 0% [content] 1fr [arrow] auto;
    padding: calc(var(--size-48) + var(--size-40));
    row-gap: unset;
  }

  @media (--viewport-lg) {
    grid-template-rows: 1fr;
    grid-template-columns: 20% [content] 60% [arrow] 20%;
  }

  &.green,
  &.white {
    color: var(--color-white);
  }

  & > .contentLayout {
    grid-column: content;
    align-self: end;
    position: static;
    z-index: 1;
    margin-bottom: var(--size-32);
  }

  & > .navigationButtonLayout {
    grid-column: arrow;
    position: static;
    align-self: end;
    justify-self: end;
    width: var(--size-32);
    height: var(--size-32);
  }
}

.componentContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.componentBackgroundImage {
  pointer-events: none;

  & > .feedItemImageLayout {
    width: 100%;
    height: 100%;
  }
}

.componentNavigationButton {
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.metaTagWrapper {
  overflow: hidden;
}
