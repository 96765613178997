.component {
  position: relative;
  display: grid;

  & > * {
    grid-area: 1 / -1;
  }

  & > .contentLayout {
    margin-inline: var(--size-100);
    height: 100%;
  }

  & > .buttonContainer {
    position: static;
    justify-self: flex-end;
    align-self: flex-end;
  }
}

.buttonContainer {
  position: relative;
  padding: var(--size-24);

  & > .plusButtonLayout {
    position: static;
  }
}

.componentContentBase {
  display: flex;
  flex-direction: column;
  padding-inline: unset;
  padding-top: var(--size-20-54);
  gap: var(--size-20-54);
  position: relative;
  overflow: hidden;

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
    gap: var(--size-48);
    padding-block: var(--size-100);
  }

  & > .gridLayout {
    flex: 1.1;
    height: 100%;
    max-height: 65%;

    @media (--viewport-md) {
      max-height: 100%;
    }
  }

  & > .headerLayout {
    flex: 1;
  }
}

.componentContentIntro {
  & > .gridLayout {
    @media (--viewport-md) {
      max-height: 600px;
    }
  }
}

.componentGrid {
  display: grid;
  gap: var(--size-12);
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);

  & > :nth-child(1) { grid-area: 1 / 1 / 5 / 4; }
  & > :nth-child(2) { grid-area: 5 / 1 / 7 / 4; }
  & > :nth-child(3) { grid-area: 1 / 4 / 3 / 7; }
  & > :nth-child(4) { grid-area: 3 / 4 / 7 / 7; }

  &.gridLayoutWith1Items {
    & > :nth-child(1) { grid-area: 1 / 1 / 7 / 7; }
  }

  &.gridLayoutWith2Items {
    & > :nth-child(1) { grid-area: 1 / 1 / 7 / 4; }
    & > :nth-child(2) { grid-area: 1 / 4 / 7 / 7; }
  }

  &.gridLayoutWith3Items {
    & > :nth-child(3) { grid-area: 1 / 4 / 7 / 7; }
  }
}

.componentHeader {
  display: flex;

  @media (--viewport-md) {
    justify-content: center;
    align-items: center;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  padding: var(--size-24);
}
