.componentItemStandard,
.componentItemHighlighted {
  display: flex;
  justify-content: flex-start;

  & > .deckItemContentLayout {
    margin: auto 5%;
    width: 90%;
  }
}

.componentItemStandard {
  &:has(+ .componentItemStandard),
  &:last-child {
    & > .deckItemContentLayout {
      /* stylelint-disable-next-line kaliber/layout-related-properties */
      border-bottom: 1px solid var(--color-black);
    }
  }
}

.componentItemHighlighted {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: var(--size-8);
  background-color: var(--color-cream-400);

  & > .deckItemContentLayout {
    flex: 7;
  }

  & > .deckItemImageLayout {
    flex: 3;
    max-width: 160px;
    min-height: 100%;
    overflow: hidden;
  }
}

.componentDeckItemContentLink {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--size-16) 0 var(--size-20);

  &.read {
    color: var(--color-gray-500);
  }

  & > .clickableComponentLayout {
    position: static;
  }
}

.rubricContainer {
  display: flex;
  align-items: center;
  gap: var(--size-8);
}

.rubricText {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  font-size: var(--font-size-16);

  & > .iconLayout {
    width: var(--size-12);
    height: var(--size-12);
  }
}

.activeArticle {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  font-size: var(--font-size-14);
  color: var(--color-gray-500);
  font-style: italic;

  &::before {
    content: '•';
    display: inline-block;
  }
}

.componentLink,
.componentButton {
  position: relative;
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-600);
  line-height: 3rem;
  text-align: left;
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.componentHighlightedImage {
  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}
