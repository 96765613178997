.component {
  display: flex;
  gap: var(--font-gap);
  flex-wrap: wrap;
  font-size: var(--font-size-32-46);
  font-weight: var(--font-weight-600);
  line-height: 1.2;
}

.componentWithImage {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-areas: "content";
  align-items: flex-end;
  color: var(--color-white);

  & > * {
    z-index: 1;
    grid-area: content;
  }

  & > .imageLayout {
    z-index: -1;
    position: relative;
    width: 100%;
  }
}

.quoteContainer {
  padding-bottom: var(--size-40);
}
