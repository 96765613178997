.componentBase {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-heading-group-title);
}

.componentXs {
  font-size: var(--font-size-22);
}

.componentSm {
  font-size: var(--font-size-28);
  font-size: var(--font-size-28-32);
}
