/* @font-face declarations */
@font-face {
  font-family: 'Myriad';
  src: url('https://www.rabobank.nl/static/generic/font/myriad/fonts/b3e9f984-f8ca-4650-90c3-d6f0aca0a27d.eot?#iefix');
  src:
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/b3e9f984-f8ca-4650-90c3-d6f0aca0a27d.eot?#iefix') format('eot'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/e7e30ff2-3a95-49b0-bbf9-024f40ead426.woff2') format('woff2'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/b984f4e8-e37d-4502-bead-ffd991e64d1f.woff') format('woff'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/82d18baa-8811-4aa8-9338-9bafa08350d0.ttf') format('truetype'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/20e14bb9-6aec-47a9-8ef8-5613bf1d6eae.svg#20e14bb9-6aec-47a9-8ef8-5613bf1d6eae') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* 'Myriad Light Italic' */
@font-face {
  font-family: 'Myriad';
  src: url('https://www.rabobank.nl/static/generic/font/myriad/fonts/7f8bf145-43b4-4e18-8ab6-38552ac83b70.eot?#iefix');
  src:
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/7f8bf145-43b4-4e18-8ab6-38552ac83b70.eot?#iefix') format('eot'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/6ad65c4a-999a-4b06-92ad-075dd9a6c0a7.woff2') format('woff2'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/3efa8eef-d81a-4a5b-ba15-94c5afa463cf.woff') format('woff'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/0ac3a8d1-ea58-4bc8-b995-cd7fce51290e.ttf') format('truetype'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/f360572f-8d74-4e11-b63c-91a55e17c82d.svg#f360572f-8d74-4e11-b63c-91a55e17c82d') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* 'Myriad Regular' */
@font-face {
  font-family: 'Myriad';
  src: url('https://www.rabobank.nl/static/generic/font/myriad/fonts/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix');
  src:
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix') format('eot'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/3b0f1c67-c2e4-4df6-976f-49d52e45aba1.woff2') format('woff2'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/c5b1c170-d8f7-41f9-85c2-0ab670780c6b.woff') format('woff'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/ba1ff8f6-0c27-4e7b-8be5-818b6c3dd801.ttf') format('truetype'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/a379413e-a0b8-44dc-b250-1e000e4092a4.svg#a379413e-a0b8-44dc-b250-1e000e4092a4') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* 'Myriad Italic' */
@font-face {
  font-family: 'Myriad';
  src: url('https://www.rabobank.nl/static/generic/font/myriad/fonts/b7dea71a-f8e7-42e0-b03b-ae1b0b6eb0cd.eot?#iefix');
  src:
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/b7dea71a-f8e7-42e0-b03b-ae1b0b6eb0cd.eot?#iefix') format('eot'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/a56f9fd5-b438-4696-ae62-d273eb2e4c1b.woff2') format('woff2'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/7becdf28-cd45-4f8a-bcab-d8c861a8ebc5.woff') format('woff'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/b90a1a2e-a700-4528-aa00-535c93ecf8a9.ttf') format('truetype'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/85c745b1-d826-4e09-988f-82cd152fb0db.svg#85c745b1-d826-4e09-988f-82cd152fb0db') format('svg');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* 'Myriad SemiBold' */
@font-face {
  font-family: 'Myriad';
  src: url('https://www.rabobank.nl/static/generic/font/myriad/fonts/f71415c0-f68d-42cd-a948-05af81548ea5.eot?#iefix');
  src:
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/f71415c0-f68d-42cd-a948-05af81548ea5.eot?#iefix') format('eot'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/0b6110f9-6072-46b9-98af-7d09f7c895b8.woff2') format('woff2'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/c22866d7-ca67-4170-a113-cae280eea669.woff') format('woff'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/19ce9ea9-076e-4dcd-91a1-454f4830f120.ttf') format('truetype'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/18915124-98e4-4245-9e10-b921e09a6704.svg#18915124-98e4-4245-9e10-b921e09a6704') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* 'Myriad SemiBold Italic' */
@font-face {
  font-family: 'Myriad';
  src: url('https://www.rabobank.nl/static/generic/font/myriad/fonts/e3c5d5a6-3f08-4d0c-a4af-3d90f6fa03ee.eot?#iefix');
  src:
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/e3c5d5a6-3f08-4d0c-a4af-3d90f6fa03ee.eot?#iefix') format('eot'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/2cc3ff2f-19fe-458d-99da-2fb1acb43d81.woff2') format('woff2'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/f83744dc-5c76-4309-bea0-03c18f58997f.woff') format('woff'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/dc5e0550-21b2-4664-a54a-248791c96660.ttf') format('truetype'),
    url('https://www.rabobank.nl/static/generic/font/myriad/fonts/f1682532-45c5-4c1b-a058-a65e600e0100.svg#f1682532-45c5-4c1b-a058-a65e600e0100') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
