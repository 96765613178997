.componentBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  overflow: visible;
}

.slider {
  --slides-per-view: 1.1;

  @media (--viewport-md) {
    --slides-per-view: 3.6;
  }

  display: grid;
  grid-auto-flow: column;
  padding: 0 var(--size-24) var(--size-40) 0;
  overflow: visible !important;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

._rootSlide {
  width: auto !important;
}

.slide {
  --container-width: calc(min(100vw, var(--max-width, 1600px)) - (2 * var(--container-padding-x)));
  --slide-width: calc(var(--container-width) / var(--slides-per-view));
  --spacing: var(--size-16);

  @media (--viewport-md) {
    --spacing: var(--size-32);
  }

  overflow: visible !important;

  & > * {
    width: var(--slide-width);
  }

  &:not(:last-child) {
    & > * {
      margin-right: var(--spacing);
    }
  }
}
