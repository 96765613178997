.component {
  z-index: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .backgroundLayout {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & > .largeLogoContainer {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & > .logo {
    z-index: 1;
    position: relative;
    width: var(--size-80);
    top: var(--size-24);
  }

  & > .content {
    z-index: 2;
    position: absolute;
    flex-grow: 1;
    width: 100%;
    top: 0;
  }
}

.largeLogoContainer {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  align-items: stretch;

  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentBackgroundImage {
  position: relative;
  background-color: var(--color-black);

  & > .imageLayout {
    height: 100%;
  }

  & > .backgroundOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.backgroundOverlay {
  opacity: 0;
  background-color: var(--color-black--20);
  backdrop-filter: blur(50px);
}
