.componentWithBorderedBackgroundImage,
.componentWithBackgroundImage {
  display: flex;
  flex-direction: column-reverse;
  padding: var(--size-32);
  position: relative;
  z-index: 0;
  background-color: var(--color-gray-200);
  transform-origin: bottom;
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-48);
  }

  & > * {
    z-index: 1;
  }

  & > .backgroundImageLayout {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.componentWithBorderedBackgroundImage {
  --border-padding: var(--size-32);

  @media (--viewport-md) {
    --border-padding: var(--size-48);
  }

  border-radius: var(--size-12);
  background: var(--gradient-cream);
  color: var(--color-white);

  &::after,
  & > .backgroundImageLayout {
    width: calc((100vw - var(--scrollbar-width)) - (2 * var(--border-padding, 0)));
    height: calc(100% - (2 * var(--border-padding, 0)));
    margin: var(--size-32);

    @media (--viewport-md) {
      margin: var(--size-48);
    }
  }
}

.componentWithGradientBackground {
  display: flex;
  flex-direction: column;
  transform-origin: bottom;
  overflow: hidden;

  &.orange {
    background: var(--gradient-cream);
  }

  &.green {
    /* TODO: Erwin - check if these should be the same as PopupContentDefault.componentPopupContentCard colors */
    background: var(--gradient-green);
  }

  &.cyan {
    background: var(--gradient-cyan);
  }

  &.cyanReverse {
    background: var(--gradient-cyan-reverse);
  }
}

.componentHeader {
  padding: var(--size-24);
}

.componentHeading {
  font-size: var(--font-size-32-40);
  font-weight: var(--font-weight-600);
  line-height: 1;
}

.componentSubHeading {
  text-transform: uppercase;
  font-size: var(--font-size-24-28);
  font-weight: var(--font-weight-700);
}

.componentPlusIcon,
.componentPlusButtonLink {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.plusIconContainer {
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: var(--color-white);
  padding: var(--size-8);
  width: var(--size-40) !important;
  height: var(--size-40) !important;
}
