.imageContainerMobile,
.imageContainerDesktop {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.imageContainerMobile {
  @media (--viewport-md) {
    display: none;
  }
}

.imageContainerDesktop {
  @media not (--viewport-md) {
    display: none;
  }
}
