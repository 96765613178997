.component {
  display: grid;
  position: relative;
  padding:
    var(--size-48)
    var(--size-24)
    calc(var(--size-24) + var(--size-52))
    var(--size-24);

  @media (--viewport-md) {
    padding:
      var(--size-32)
      calc(var(--size-24) + var(--size-52))
      var(--size-52)
      calc(var(--size-24) + var(--size-52));
  }

  & > * {
    grid-area: 1 / -1;
  }

  & > .contentLayout {
    height: 100%;
  }

  & > .buttonContainer {
    position: static;
    justify-self: flex-end;
    align-self: flex-end;
  }

  & > .navigationButtonLayout {
    position: static;
    width: var(--size-32);
    height: var(--size-32);
    justify-self: end;
    align-self: end;
  }
}

.componentNavigationButton {
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
  }
}

.componentContentBase {
  display: grid;
  gap: var(--size-20);
  grid-template-rows: 1fr auto;
  position: relative;
  overflow: hidden;

  @media (--viewport-lg) {
    align-items: center;
    gap: var(--size-48);
  }

  & > .gridLayout {
    width: 90%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    justify-self: center;

    @media (--viewport-lg) {
      width: 100%;
      max-height: max(65%, 65vh);
      max-width: 100%;
    }
  }
}

.componentContentIntro {
  & > .gridLayout {
    @media (--viewport-md) {
      max-height: 600px;
    }
  }
}

.componentGrid {
  display: grid;
  gap: var(--size-12);
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--item-count, 1), 1fr);
  padding: 0 var(--size-24);
  overflow: hidden;

  @media (--viewport-lg) {
    grid-template-columns: repeat(var(--item-count, 1), 1fr);
    grid-template-rows: 1fr;
    align-items: center;
  }
}

.componentHeader {
  display: flex;

  @media (--viewport-lg) {
    justify-content: center;
    align-items: center;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  padding: var(--size-24) 0 0;

  @media (--viewport-md) {
    padding: 0 var(--size-24) var(--size-24) var(--size-24);
  }
}
