.component {
  position: relative;
  z-index: 0;
  background-color: var(--color-black);

  & > .contentLayout {
    min-height: 100%;
  }
}

.componentContent {
  display: flex;
  flex-direction: column;
  background-color: var(--color-black);
  position: relative;
  overflow: hidden;
  z-index: 0;

  & > .gedichtLayout {
    position: relative;
    z-index: 2;
    margin: var(--size-32);
    max-height: 60dvh;
  }

  & > .imageContainer {
    position: absolute;
    height: 100%;
    top: 0;
  }

  & > .audioPlayerLayout {
    position: absolute;
    width: 100%;
    bottom: var(--size-100);
    z-index: 2;
  }

  & > .creditsLayout {
    position: absolute;
    bottom: var(--size-40);
    left: var(--size-32);
    z-index: 4;
    max-width: 75%;
    line-height: var(--line-height-text);

    @media (--viewport-md) {
      max-width: 400px;
    }
  }

  & > .navigationButtonLayout {
    z-index: 3;
    width: var(--size-32);
    height: var(--size-32);
    margin-bottom: calc(var(--size-24) + var(--size-72));
    margin-right: var(--size-24);
    align-self: flex-end;
    margin-top: auto;

    @media (--viewport-md) {
      margin-bottom: var(--size-52);
      margin-right: var(--size-52);
    }
  }
}

.imageContainer {
  z-index: 0;
  position: relative;

  &::after {
    content: '';
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: var(--color-black--20);
  }

  & > .imageLayout {
    min-height: 100%;
  }
}

.componentGedicht {
  --top-mask-size: 50px;
  --bottom-mask-size: 50px;

  position: relative;
  mask-image: linear-gradient(to bottom, transparent 0, black var(--top-mask-size, 0), black calc(100% - var(--bottom-mask-size, 0)), transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0, black var(--top-mask-size, 0), black calc(100% - var(--bottom-mask-size, 0)), transparent 100%);

  & > .textLayout {
    max-height: 100%;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  position: relative;
  color: var(--color-white);
  padding-top: 5dvh;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    margin-bottom: var(--size-8);
  }
}

.componentHeading {
  font-size: var(--font-size-32-40);
  font-weight: bold;
}

.componentLink {
  color: var(--color-white);
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
  }
}

.screenreaderOnly {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
