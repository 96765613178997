.component {
  display: grid;
  row-gap: var(--size-8);
  column-gap: var(--size-8);
  grid-auto-flow: row dense;
  grid-template-columns: 1fr;

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.dot {
  outline: 2px solid var(--outline, var(--color));
  background-color: var(--color);
  border-radius: 50%;
}

.legendItem {
  display: grid;
  align-items: center;
  font-size: var(--font-size-14-16);
  grid-template-columns: var(--margin-left) 1fr;
  gap: var(--size-8);

  & > :first-child {
    justify-self: end;
  }

  & > .dot {
    height: var(--size-8);
    width: var(--size-8);
  }
}

.legendText {
  display: inline-flex;
  gap: var(--size-4);
  line-height: 1;
}
