.page {
  position: relative;
  display: grid;
  grid-template-rows: var(--size-100) 1fr;
  grid-template-areas:
    'logo'
    'content';
  justify-items: center;
  background-color: var(--color-brown-500);
  width: 100%;
  height: 100dvh;
  overflow: hidden;

  & > .logoLayout {
    grid-area: logo;
    width: max-content;
  }

  & > .contentLayout {
    grid-area: content;
    max-width: 540px;
    height: 100%;
  }

  & > .mapLayout {
    grid-area: content;
    height: 100%;

    @media (--viewport-md) {
    }
  }
}

.componentContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: var(--size-100);
  text-align: center;
}

.title {
  font-size: var(--font-size-75-115);
  line-height: var(--line-height-heading);
  color: var(--color-white);
}

.description {
  color: var(--color-white);
  font-size: var(--font-size-18-22);
  line-height: var(--line-height-text);
}

.componentMap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: var(--size-100);
  color: var(--color-white--50);

  & > .iconLayout {
    height: 75%;
  }
}
