.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  gap: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-40);
    align-items: center;
    gap: var(--size-48);
  }

  & > .containerLayout {
    justify-self: flex-start;
    align-self: flex-start;
    max-width: 100%;
    min-width: 0;
  }
}

.headingAndButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.componentHeading {
  padding: 0 var(--size-24) var(--size-24);
  color: var(--color-black);
  font-family: var(--font-family-base);
  font-size: var(--font-size-32-46);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-heading-group-title);
}

.sliderContainer {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  & > * {
    grid-area: 1 / 1;
  }
}

.componentSliders,
.componentSlider {
  & > .toggleLayout {
    width: max-content;
  }
}
