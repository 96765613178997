.component {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--color-black--20);
  border-radius: var(--radius-max);
  padding: var(--size-4);
  z-index: 0;

  & > *[data-index='0'] {
    z-index: 1;
    grid-row: 1;
    grid-column: 1;
  }

  & > *[data-index='1'] {
    z-index: 1;
    grid-row: 1;
    grid-column: 2;
  }

  &:focus-within {
    --focus-color: var(--color-black--30);
  }

  &::before {
    content: '';
    grid-row: 1;
    grid-column: 1;
    transition: outline 150ms ease;
    transform: translateX(calc(var(--active-index-spring, 0) * 100%));
    outline: 2px solid var(--focus-color, transparent);
    outline-offset: -2px;
    background-color: white;
    border-radius: inherit;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}

.componentLabel {
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-max);
  padding: var(--size-8) var(--size-16);
  line-height: var(--line-height-text);
  font-size: var(--font-size-14);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &:has(:checked) {
    color: var(--color-gray-900);
  }

  &.backgroundLeft {
    --gradient-offset: calc(var(--active-index-spring) * 100%);
    background: linear-gradient(to right, var(--color-white) var(--gradient-offset), var(--color-gray-900) var(--gradient-offset) );
  }

  &.backgroundRight {
    --gradient-offset: calc((100% - (var(--active-index-spring) * 100%)));
    background: linear-gradient(to left, var(--color-white) var(--gradient-offset), var(--color-gray-900) var(--gradient-offset) );

  }
}
