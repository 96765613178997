.componentBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  & > .subtitleLayout {
    order: -1;
  }
}

.componentTitle,
.componentTitleSnackables {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-heading-group-title);
}

.componentTitle {
  font-size: var(--font-size-36-57);
}

.componentTitleSnackables {
  font-size: var(--font-size-36-57);
}

.componentSubtitle,
.componentSubtitleSnackables {
  font-family: var(--font-family-condensed-heading);
  font-weight: var(--font-weight-700);
  line-height: var(--line-height-heading-group-subtitle);
  font-style: normal;
}

.componentSubtitle {
  font-size: var(--font-size-24-28);
}

.componentSubtitleSnackables {
  font-size: var(--font-size-25-36);
  text-transform: uppercase;
}
