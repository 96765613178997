.componentContainer {
  z-index: 0;
  position: relative;

  & > .nextArticleLinkLayout {
    z-index: 2;
    width: 100%;
    height: var(--size-100);
    top: 0;
  }

  & > .nextArticleHeroLayout {
    position: fixed;
    width: 100%;
    height: 0;
    bottom: 0;
  }
}

.componentHero {
  z-index: 0;
  position: relative;
  padding-right: var(--size-100);
  overflow: hidden;

  & > .nextArticleHeroImageContainer {
    position: absolute;
    width: 100%;
    height: 100vh;
    bottom: 0;
  }

  & > .nextArticleHeadingContainer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.nextArticleHeroImageContainer {
  z-index: 0;
  position: relative;
  transform-origin: top;
  will-change: transform;

  &.triggerNextArticle {
    & > .articleHeroLayout {
      /* stylelint-disable-next-line kaliber/layout-related-properties */
      padding-right: var(--scrollbar-width);
    }
  }

  & > .articleHeroLayout {
    position: absolute;
    width: 100%;
    height: 100vh;
    bottom: 0;
  }

  & > .nextArticleLinkHeroButton {
    z-index: 3;
    width: 100%;
    height: 100%;
  }
}

.nextArticleLinkHeroButton {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.componentLink {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-900);
  background-color: var(--color-white);
  box-shadow: var(--shadow-md);

  & > .nextArticleLink {
    position: static;
  }
}

.nextArticleLink {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--size-8);
  font-size: var(--font-size-14);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.nextArticleHeadingContainer {
  pointer-events: none;

  &.triggerNextArticle {
    & > .articleIntroLayout {
      /* stylelint-disable-next-line kaliber/layout-related-properties */
      padding-right: var(--scrollbar-width);
    }
  }

  & > .articleIntroLayout {
    height: 100lvh;
    width: 100%;
    bottom: 0;
  }
}

.overlay {
  pointer-events: none;
}
