.component {
  color: var(--color-white);

  & > .splashLayout {
    height: 100dvh;
  }
}

.container {
  & > .grid {
    height: 100dvh;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'content image';
  place-items: center;

  @media (--viewport-lg) {
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 2.5fr;
  }

  & > .mapLayout {
    width: 70vmin;
    grid-area: content;

    @media (--viewport-md) {
      grid-area: image;
    }
  }

  & > .contentLayout {
    grid-area: content;
  }
}

.componentContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > .arrowLink {
    margin-top: var(--size-24);
  }

  & > .iconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.heading {
  font-size: var(--font-size-75-115);
  line-height: var(--line-height-heading);
}

.paragraph {
  font-size: var(--font-size-18-22);
  line-height: var(--line-height-text);
}
