.component {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-white);

  & > .inputContainer {
    width: 100%;
  }

  & > .iconLayout {
    position: absolute;
    width: var(--size-24);
    height: var(--size-24);
    left: var(--size-20);
  }

  & > .deletePromptButton {
    position: absolute;
    width: var(--size-16);
    height: var(--size-16);
    right: var(--size-20);
  }
}

.inputContainer {
  & > .inputLayout {
    width: 100%;
    height: var(--size-48);
  }
}
