
.componentBase,
.componentBordered {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  place-content: center;
  position: relative;
  overflow: hidden;
  z-index: 0;

  & > * {
    grid-area: 1 / 1;
  }
}

.componentDefault {
  background-color: var(--color-white);

  & > .imageLayout {
    width: 100vw;
    height: 100lvh;
    top: 0;
  }
}

.componentImage {
  overflow: hidden;
  border-radius: inherit;

  & > .imageCoverLayout {
    width: 100%;
    height: 100%;
  }
}

/* 9 / 16 = 0. *something* */
*[data-aspect-ratio^='0.'] {
  @media (--viewport-md) {
    display: none;
  }
}

/* 16 / 9 = 1. *something* */
*[data-aspect-ratio^='1.'] {
  @media not (--viewport-md) {
    display: none;
  }
}

.componentSingleSnackable {
  background: var(--gradient-cream);

  & > .containerLayout {
    width: 100vw;
  }
}

.singleSnackableContentWrapper {
  & > .singleSnackableContentContainer {
    width: 100%;
    height: 100lvh;
  }
}

.singleSnackableContentContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 2fr;
  grid-template-areas:
    "image"
    "header";
  padding: var(--size-24);
  gap: var(--size-48);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "image header";
    align-items: center;
    justify-items: flex-start;
  }

  & > .imageLayout {
    grid-area: image;
    max-width: 270px;
    width: 100%;
    place-self: center;

    @media (--viewport-md) {
      max-width: 500px;
    }
  }

  & > .headingContainer {
    grid-area: header;
  }

  & > .plusButtonLayout {
    grid-area: header;
    position: static;
    justify-self: end;
    align-self: end;
  }
}

.componentMemberOffer,
.componentSnackables {
  display: flex;
  justify-content: center;
  background: var(--gradient-green);
}

.componentMemberOffer {
  padding-block: 100px;
  padding-inline: var(--container-padding-x);

  & > .memberOfferCardLayout {
    max-width: var(--container-max-width-lg);
    height: auto;
  }
}

.componentSnackables {
  & > .snackablesCardLayout {
    max-width: var(--container-max-width-md);
    height: 100lvh;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: var(--size-12);
  aspect-ratio: 3 / 4;

  & > :nth-child(1) { grid-area: 1 / 1 / 5 / 4; }
  & > :nth-child(2) { grid-area: 5 / 1 / 7 / 4; }
  & > :nth-child(3) { grid-area: 1 / 4 / 3 / 7; }
  & > :nth-child(4) { grid-area: 3 / 4 / 7 / 7; }

  &.gridLayoutWith1Items {
    & > :nth-child(1) { grid-area: 1 / 1 / 7 / 7; }
  }

  &.gridLayoutWith2Items {
    & > :nth-child(1) { grid-area: 1 / 1 / 7 / 4; }
    & > :nth-child(2) { grid-area: 1 / 4 / 7 / 7; }
  }

  &.gridLayoutWith3Items {
    & > :nth-child(3) { grid-area: 1 / 4 / 7 / 7; }
  }
}

.gridItem {
  overflow: hidden;

  & > .gridImage {
    height: 100%;
    width: 100%;
  }
}

.gridImage {
  & > .imageLayout {
    object-fit: cover;
    height: 100%;
  }
}

.componentBordered {
  --border-margin: var(--size-32);

  @media (--viewport-md) {
    --border-margin: var(--size-48);
  }

  display: grid;
  background: var(--gradient-cream);

  &::after {
    margin: var(--border-margin);
    border-radius: var(--size-12);
    width: calc(var(--screen-width) - (2 * var(--border-margin)));
    height: calc(100lvh - (2 * var(--border-margin)));
  }

  & > .imageLayout {
    border-radius: var(--size-12);
    margin: var(--border-margin);
    width: calc(var(--screen-width) - (2 * var(--border-margin)));
    height: calc(100lvh - (2 * var(--border-margin)));
  }
}

.rounded {
  border-radius: var(--size-12);
  overflow: hidden;
}
