.shareButton {
  display: grid;
  place-items: center;
  border-radius: 50%;
  border: 1px solid var(--color-white--20);
  background-color: var(--color-black--10);
  backdrop-filter: blur(var(--blur-10));
  pointer-events: auto;

  & > .shareIconLayout {
    margin-top: var(--size-4);
    width: var(--size-24);
    height: var(--size-24);
  }
}
