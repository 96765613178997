.componentContent,
.componentSanity,
.componentWoodwing {
  --margin-sm: var(--size-8);
  --margin-md: var(--size-12);
  --margin-lg: var(--size-16);
  --margin-xl: var(--size-40);

  @media (--viewport-md) {
    --margin-sm: var(--size-12);
    --margin-md: var(--size-16);
    --margin-lg: var(--size-20);
    --margin-xl: var(--size-48);
  }

  --content-margin-sm: var(--margin-sm) 0 var(--margin-sm);
  --content-margin-md: var(--margin-md) 0 var(--margin-md);
  --content-margin-lg: var(--margin-lg) 0 var(--margin-lg);
  --content-margin-xl: var(--margin-xl) 0 var(--margin-xl);

  display: flex;
  flex-direction: column;

  &.snackable,
  &.default {
    .sanityHeadingLayout,
    & > .headingLayout {
      margin: var(--content-margin-sm);
      margin-bottom: 0;
    }

    .sanityTextLayout,
    & > .textLayout,
    & > .paragraphsLayout {
      margin: var(--content-margin-sm);
    }

    & > .ctaLayout {
      align-self: center;
      margin-top: var(--size-12);
      margin-bottom: var(--size-12);
    }

    & > .kaderLayout {
      margin: var(--content-margin-md);
    }

    & > .nameLayout {
      margin-top: var(--size-24);
    }
  }

  &.snackable {
    & > .ctaLayout {
      align-self: flex-start;
    }
  }

  &.page {
    .sanityHeadingLayout,
    & > .headingLayout {
      margin: var(--content-margin-sm);
      margin-bottom: 0;
    }

    .sanityTextLayout,
    & > .textLayout,
    & > .paragraphsLayout {
      margin: var(--content-margin-sm);
    }

    .sanityIntroLayout,
    & > .introLayout,
    & > .ctaLayout {
      margin: var(--content-margin-md);
    }

    .zoomableLayout,
    & > .zoomableLayout {
      margin: var(--content-margin-lg);
    }

    & > .imageSliderLayout,
    & > .imageLayout,
    & > .quoteLayout,
    & > .quoteWithImageLayout,
    & > .videoLayout,
    & > .audioLayout,
    & > .conclusionLayout,
    & > .kaderLayout {
      margin: var(--content-margin-xl);
    }
  }

  .sanityTextLayout,
  & > .textLayout,
  & > .paragraphsLayout {
    &:has(+ .headingLayout) {
      margin-bottom: var(--margin-xl);
    }
  }

  &.conclusionBase {
    font-family: var(--font-family-base);
  }
}


.componentSharedText {
  &.default,
  &.snackable {
    padding: var(--size-8) 0;
    font-family: var(--font-family-base);
    font-size: var(--font-size-16);
    color: var(--color-gray-400);
  }

  &.page {
    display: inline-block;
    font-family: var(--font-family-alt);
    line-height: var(--line-height-text);
  }
}

.componentWoodwingName {
  font-weight: var(--font-weight-600);
}

.componentWoodwingPosition {
  &.page {
    font-family: var(--font-family-alt);
  }
}

.componentWoodwingSubtitle {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-18-22);
  line-height: var(--line-height-subheading);
}

.componentWoodwingParagraphs {
  &.default,
  &.snackable {
    padding: var(--size-8) 0;
    font-family: var(--font-family-base);
    font-size: var(--font-size-16);
    color: var(--color-gray-400);
  }

  &.page {
    font-family: var(--font-family-alt);
    line-height: var(--line-height-text);
  }

  &.conclusion {
    font-weight: var(--font-weight-400);
    color: var(--color-black);
  }
}

.componentWoodwingParagraphLink {
  text-decoration: underline;
}

.componentSharedHeading {
  &.default,
  &.page {
    font-family: var(--font-family-base);
    font-size: var(--font-size-24-28);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-heading);
  }

  &.conclusion {
    font-family: var(--font-family-condensed-heading);
    font-size: var(--font-size-28-32);
    font-weight: var(--font-weight-600);
    text-transform: uppercase;
  }
}

.componentWoodwingIntro,
.componentSanityIntroText {
  &.page {
    display: inline-block;
    font-family: var(--font-family-base);
    font-size: var(--font-size-18-22);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-text);
  }

  &.conclusion {
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-14-20);
    color: var(--color-gray-400);
    padding-bottom: var(--size-8);
  }
}

.componentSharedImage {
  display: flex;
  justify-items: flex-end;
  position: relative;
  z-index: 0;

  & > .captionContainer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.componentWoodwingQuoteWithImage {
  position: relative;
  z-index: 0;

  & > .captionContainer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.captionContainer {
  padding: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-40);
  }
}

.componentSanityList,
.componentWoodwingList {
  list-style-type: unset;
  padding-left: var(--size-24);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16-20);
}

.componentWoodwingConclusion {
  padding-top: var(--size-32);
  border-top: solid 1px var(--color-gray-900);
}

.componentWoodwingGedicht {
  &.page {
    padding: var(--size-20) var(--size-48);
    text-align: center;

    & > .poemLineLayout:not(:last-child) {
      margin-bottom: var(--size-12);
    }
  }
}

.componentWoodwingKader {
  padding: var(--size-24) var(--size-32);
  background-color: var(--color-cyan-300);
}
