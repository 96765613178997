.component {
  & > .contentContainerLayout {
    padding: 0;
  }
}

.background {
  background: var(--color-cyan-100--20);
}

.container {
  display: flex;
  gap: var(--size-20);
  flex-direction: column;
  align-items: flex-start;
  padding-block: var(--size-64);

  & > .articleCardSliderLayout {
    margin-top: var(--size-12);
  }
}

.readMoreTitle {
  line-height: var(--line-height-heading);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-28-32);
  font-style: italic;
}
