.title {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-28-32);
  font-style: italic;
}

.text {
  color: var(--color-gray-900);
  font-size: var(--font-size-14-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-text);
}

.component {
  display: flex;
  gap: var(--size-16);
  flex-direction: column;
  padding-block-start: var(--size-64);
  padding-block-end: var(--size-8);
}

.componentColumns {
  display: grid;
  gap: var(--size-32);
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  @media (--viewport-md) {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
  }

  & > .issueCardLayout {
    max-width: 90vw;
    width: 100%;

    @media (--viewport-md) {
      max-width: min(275px, 90vw);
    }
  }

  & > .text {
    align-self: flex-end;
    margin-bottom: var(--size-48);

    @media not (--viewport-md) {
      margin-left: var(--size-16);
    }
  }
}

.issueTextTitle {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16-20);
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);

  & > .paragraph {
    max-width: 50ch;
    word-wrap: balance;
  }
}
