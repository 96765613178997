:root {
  --duration-50: 50ms;
  --duration-100: 100ms;
  --duration-200: 200ms;
  --duration-300: 300ms;
  --duration-400: 400ms;
  --duration-600: 600ms;
  --duration-1500: 1500ms;
  --duration-2000: 2000ms;

  --ease: ease;
}
