.component {
  display: grid;
  position: relative;
  color: var(--color-brown-500);
  background-color: var(--color-brown-500);

  & > .circleLayout {
    position: absolute;
    place-self: center;
    width: 250px;
    height: 250px;
  }
}

.componentCircleBase {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  animation: rotate var(--duration-1500) ease-in-out infinite;

  & > * {
    position: absolute;
    width: 100%;
    height: 50%;
    margin-top: 50%;
  }
}

.componentCircleWhite,
.componentCircleGray {
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: -50%;
  }

  &::after {
    content: '';
    width: 80%;
    height: 160%;
    position: absolute;
    margin-top: -40%;
    margin-left: 10%;
    background: currentColor;
    border-radius: 50%;
  }
}

.componentCircleWhite {
  &::before {
    background: linear-gradient(90deg, var(--color-brown-500--10), var(--color-white--50));
  }
}

.componentCircleGray {
  &::before {
    background: linear-gradient(90deg, var(--color-white--50), var(--color-gray-900--50));
  }
}

@keyframes rotate {
  from { transform: rotate(0turn); }
  to { transform: rotate(1turn); }
}
