.page {
  padding-top: var(--size-80);
  padding-bottom: var(--size-80);
  background-color: var(--color-cyan-300);
}

.header {
  & > .buttonLayout {
    width: fit-content;
    height: var(--size-40);
  }

  & > .heroHeadingLayout {
    margin-top: var(--size-32);
  }
}
